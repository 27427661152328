<template>
    <div class="item-wrapper">
        <div class="title-container">
            <h3 class="w-80">{{ $filters.getTranslationByKey(item.question) }}</h3>
            <CRButton close @click="$emit('deleted', item)" />
        </div>
        <div class="mb-16" v-html="$filters.getTranslationByKey(item.answer)" />
        <div class="mb-10">
            <CRButton @click="$emit('update', item)">
                {{ $t('buttons.update') }}
            </CRButton>
        </div>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton';

    export default {
        name: 'FaqItem',
        components: { CRButton },
        props: {
            item: {
                type: Object,
                default: () => {},
            },
        },
        emits: ['deleted', 'update'],
    };
</script>

<style scoped lang="scss">
    .item-wrapper {
        @include column-align-start-justify-between;

        width: 100%;

        margin-bottom: 20px;
        padding: 10px;

        box-shadow: $box-shadow-dark;

        border-radius: $br-10;

        .title-container {
            @include row-align-start-justify-between;

            width: 100%;

            margin-bottom: 10px;
        }
    }
</style>
