<template>
    <BasicModal @close="$emit('close')">
        <div class="container">
            <h2 class="my-15">{{ $t('buttons.addFaqItem') }}</h2>
            <LangButtons class="my-15" :locale="locale" @selectLocale="selectLocale" />
            <CRInput
                class="my-15"
                v-model="faqItem.question[locale]"
                required
                :errors="errors?.question"
                :label="$t('main.faqSection.faqQuestion')"
                :placeholder="$t('main.faqSection.faqQuestion')"
                @onChange="$filters.clearError(errors, 'question')"
            />
            <Wysiwyg
                class="my-15"
                v-model="faqItem.answer[locale]"
                :key="componentKey"
                contentType="html"
                :placeholder="$t('main.faqSection.faqAnswer')"
                theme="snow"
            />
            <CRButton class="my-15" @click="handleSaveClick">
                {{ isEdit ? $t('buttons.update') : $t('buttons.create') }}
            </CRButton>
        </div>
    </BasicModal>
</template>

<script>
    import Wysiwyg from '@/components/Common/Wysiwyg/Wysiwyg.vue';
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import LangButtons from '@/components/Common/Buttons/LangButtons';
    import { langMixin, rerenderMixin } from '@/utils/mixins';
    import { FAQItemsApi } from '@/api';
    import { cloneDeep } from 'lodash';

    export default {
        name: 'CreateFAQItemModal',
        components: { LangButtons, CRInput, BasicModal, CRButton, Wysiwyg },
        mixins: [langMixin, rerenderMixin],
        props: {
            editedItem: {
                type: Object,
                default: () => {},
            },
            isEdit: {
                type: Boolean,
                default: false,
            },
            createdItemSectionId: {
                type: Number,
                default: null,
            },
        },
        emits: ['close'],
        data() {
            return {
                faqItem: {
                    question: {
                        lt: '',
                        en: '',
                    },
                    answer: {
                        lt: '',
                        en: '',
                    },
                    faqSectionId: this.createdItemSectionId,
                },
                errors: {},
            };
        },
        watch: {
            locale() {
                this.componentKey += 1;
            },
        },
        methods: {
            async handleSaveClick() {
                if (this.isEdit) await this.edit();
                if (!this.isEdit) await this.storeItem();
            },
            async storeItem() {
                try {
                    const response = await FAQItemsApi.store(this.faqItem);
                    this.$emit('close', {
                        faqItem: response.data,
                        sectionId: this.createdItemSectionId,
                        isEdit: this.isEdit,
                    });
                } catch (error) {
                    this.errors = error.errors;
                }
            },
            async edit() {
                try {
                    const response = await FAQItemsApi.update(this.editedItem.id, this.faqItem);
                    this.$emit('close', {
                        faqItem: response.data,
                        sectionId: this.createdItemSectionId,
                        isEdit: this.isEdit,
                    });
                } catch (error) {
                    this.errors = error.errors;
                }
            },
        },
        mounted() {
            if (this.editedItem) {
                this.faqItem = cloneDeep(this.editedItem);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-center-justify-center;

        width: 100%;

        padding: 28px 16px 16px;

        @include media($lg) {
            padding-left: 40px;
            padding-right: 40px;
        }

        h2 {
            font-size: $font-26;
            margin-bottom: 18px;
        }

        .button__wrapper {
            @include row-align-center-justify-around;
            flex-wrap: wrap;

            width: 300px;

            .select-btn {
                width: 30%;
                margin-bottom: 18px;
            }
        }
    }
</style>
