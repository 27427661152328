<template>
    <div class="w-full mt-10">
        <h1 class="mb-30 mt-10">{{ $t('main.faqSection.title') }}</h1>
        <h3 class="mb-30">{{ $t('main.faqSection.newSection') }}</h3>
        <LangButtons class="mb-30" :locale="locale" @selectLocale="selectLocale" />
        <CRInput
            v-model="sectionName[locale]"
            class="input mb-20"
            :label="$t('placeholder.sectionName')"
            :placeholder="$t('placeholder.sectionName')"
            :errors="errors?.name"
            @onChange="$filters.clearError(errors, 'name')"
        />
        <CRButton class="mb-20" @click="handleSectionCreate">{{ $t('buttons.create') }}</CRButton>
        <div class="f-column-align-start">
            <FaqSection
                class="w-full mb-20"
                v-for="section in sections"
                :key="section.id"
                :section="section"
                @create="createdItemSectionId = $event"
                @itemDeleted="setDeleteModalData"
                @itemUpdated="handleItemUpdateModalOpening"
                @sectionDeleted="setDeleteModalData"
                @editSection="handleSectionModalOpened"
            />
        </div>
        <CreateFAQItemModal
            :is-edit="isEdit"
            v-if="createdItemSectionId"
            @close="handleItemModalClosing"
            :created-item-section-id="createdItemSectionId"
            :edited-item="editedItem"
        />
        <UpdateFAQSectionModal
            v-if="editedSection"
            :edited-section="editedSection"
            @updated="handleSectionUpdated"
            @close="editedSection = null"
        />
        <DeleteConfirmationModal
            @delete="confirmDeleteHandler"
            v-if="deletedItem"
            :title="deleteModalTitle"
            @close="setDeleteModalData"
        />
    </div>
</template>

<script>
    import CreateFAQItemModal from '@/components/Modals/CreateFAQItemModal';
    import UpdateFAQSectionModal from '@/components/Modals/UpdateFAQSectionModal';
    import DeleteConfirmationModal from '@/components/Modals/DeleteConfirmationModal';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import LangButtons from '@/components/Common/Buttons/LangButtons';
    import FaqSection from '@/components/Admin/FAQ/FaqSection';
    import { FAQSectionApi, FAQItemsApi } from '@/api';
    import { toasterMixin, langMixin } from '@/utils/mixins';
    import { FAQDataTypeEnum } from '@/utils/enums';

    export default {
        name: 'allFaq',
        mixins: [langMixin, toasterMixin],
        components: {
            DeleteConfirmationModal,
            FaqSection,
            LangButtons,
            CRInput,
            CreateFAQItemModal,
            UpdateFAQSectionModal,
            CRButton,
        },
        data() {
            return {
                isCreateFAQItemModalOpened: false,
                isEdit: false,
                editedItem: null,
                createdItemSectionId: null,
                deletedItemSectionId: null,
                editedSection: null,
                deletedItem: null,
                deletedType: '',
                sections: [],
                sectionName: {
                    en: '',
                    lt: '',
                },
                errors: {},
            };
        },
        computed: {
            deleteModalTitle() {
                if (this.deletedType) {
                    const typeOfDeletedData =
                        this.deletedType === FAQDataTypeEnum.ITEM
                            ? this.$t('main.faqSection.element')
                            : this.$t('main.faqSection.section');
                    return this.$t('main.faqSection.title') + ' ' + typeOfDeletedData;
                }
                return '';
            },
        },
        methods: {
            async handleSectionCreate() {
                try {
                    const response = await FAQSectionApi.store({ name: this.sectionName });
                    this.sections.unshift(response.data);

                    this.notify(response.data.name);

                    this.sectionName = {
                        en: '',
                        lt: '',
                    };
                } catch (error) {
                    this.errors = error.errors;
                }
            },

            handleSectionUpdated(updatedSection) {
                let neededSection = this.sections.find((section) => section.id === updatedSection.id);
                neededSection.name = updatedSection.name;
                this.editedSection = null;
            },

            handleSectionModalOpened(section) {
                this.editedSection = section;
            },

            async deleteSection(section) {
                try {
                    await FAQSectionApi.delete(section.id);

                    this.sections = this.sections.filter((deletedSection) => deletedSection.id !== section.id);

                    this.notify(section.name, this.CRUDTypeEnum.DELETED, this.TYPE.SUCCESS);
                } catch (error) {
                    Object.entries(error.errors).forEach((element) => {
                        this.$filters.toast(element[1][0]);
                    });
                }
            },

            setDeleteModalData(deletedData = null) {
                if (deletedData) {
                    this.deletedItem = deletedData.item;
                    if (deletedData.sectionId) {
                        this.deletedItemSectionId = deletedData.sectionId;
                        this.deletedType = FAQDataTypeEnum.ITEM;
                        return;
                    }
                    this.deletedType = FAQDataTypeEnum.SECTION;
                    return;
                }
                this.deletedItem = null;
                this.deletedType = null;
            },

            async confirmDeleteHandler() {
                this.deletedType === FAQDataTypeEnum.SECTION
                    ? await this.deleteSection(this.deletedItem)
                    : await this.deleteItem({
                          item: this.deletedItem,
                          sectionId: this.deletedItemSectionId,
                      });

                this.deletedItem = null;
                this.deletedType = null;
                this.deletedItemSectionId = null;
            },

            handleItemModalClosing(payload) {
                if (payload) {
                    let neededSection = this.sections.find((section) => section.id === payload.sectionId);
                    if (payload.isEdit) {
                        let neededItem = neededSection.items.find((item) => item.id === payload.faqItem.id);

                        if (neededItem) {
                            neededItem.question = payload.faqItem.question;
                            neededItem.answer = payload.faqItem.answer;
                        }

                        this.notify(payload.faqItem.question, this.CRUDTypeEnum.UPDATED, this.TYPE.SUCCESS);
                    } else {
                        neededSection.items.push(payload.faqItem);

                        this.notify(payload.faqItem.question);
                    }
                }

                this.isEdit = false;
                this.editedItem = null;
                this.createdItemSectionId = null;
            },

            async deleteItem(deletedItem) {
                try {
                    await FAQItemsApi.delete(deletedItem.item.id);

                    let modifiedSection = this.sections.find((section) => section.id === deletedItem.sectionId);
                    modifiedSection.items = modifiedSection.items.filter((item) => item.id !== deletedItem.item.id);

                    this.notify(deletedItem.item.question, this.CRUDTypeEnum.DELETED, this.TYPE.SUCCESS);
                } catch (error) {
                    Object.entries(error.errors).forEach((element) => {
                        this.$filters.toast(element[1][0]);
                    });
                }
            },

            handleItemUpdateModalOpening(payload) {
                this.editedItem = payload.item;
                this.createdItemSectionId = payload.sectionId;
                this.isEdit = true;
            },

            async fetchSections() {
                const response = await FAQSectionApi.getAll();

                this.sections = response.data;
            },
        },
        async mounted() {
            await this.fetchSections();
        },
    };
</script>

<style lang="scss" scoped>
    h1 {
        display: none;
    }

    .input {
        max-width: 633px;
    }

    @include media($lg) {
        h1 {
            display: block;
        }

        h3 {
            font-size: $font-24;
        }
    }
</style>
