<template>
    <div class="section__wrapper">
        <div class="f-column-align-start mb-30">
            <div class="f-align-center mb-10 w-full">
                <h3 class="mr-10">{{ $filters.getTranslationByKey(section.name) }}</h3>
                <CRButton @click="$emit('editSection', section)" edit size="x-small" />
            </div>
            <CRButton @click.stop="$emit('create', section.id)">{{ $t('buttons.newItem') }}</CRButton>
        </div>
        <div class="section__wrapper-body" v-if="section.items.length">
            <FaqItem
                class="item"
                v-for="item in section.items"
                :item="item"
                :key="item.id"
                @deleted="$emit('itemDeleted', { item: $event, sectionId: section.id })"
                @update="$emit('itemUpdated', { item: $event, sectionId: section.id })"
            />
        </div>
        <div v-else class="no-items">{{ $t('main.faqSection.noItems') }}</div>
        <div class="f-align-start-justify-end">
            <CRButton @click="$emit('sectionDeleted', { item: section })" pattern="thin">
                {{ $t('main.faqSection.deleteSection') }}
            </CRButton>
        </div>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import FaqItem from '@/components/Admin/FAQ/FaqItem';

    export default {
        name: 'FaqSection',
        components: { CRButton, FaqItem },
        props: {
            section: {
                type: Object,
                default: () => {},
            },
        },
        emits: ['editSection', 'create', 'itemDeleted', 'itemUpdated', 'sectionDeleted'],
    };
</script>

<style scoped lang="scss">
    .section__wrapper {
        min-height: 530px;

        padding: 20px;

        box-shadow: $box-shadow-dark;

        border-radius: $br-20;

        .section__wrapper-body {
            @include row-align-start;
            flex-wrap: wrap;

            min-height: 300px;

            .item {
                width: 100%;
            }

            @include media($sm) {
                .item {
                    width: 49%;

                    margin-right: 2%;

                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
                }
            }

            @include media($xl) {
                .item {
                    width: 32%;

                    margin-bottom: 25px;

                    &:nth-of-type(2n) {
                        margin-right: 2%;
                    }

                    &:nth-of-type(3n) {
                        margin-right: 0;
                    }
                }
            }
        }

        .no-items {
            @include row-align-center-justify-center;

            width: 100%;
            height: 300px;

            font-weight: 700;
            font-size: $font-24;

            color: $grey-disabled;
        }
    }
</style>
