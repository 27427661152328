<template>
    <BasicModal @close="$emit('close')">
        <div class="container my-15">
            <h2 class="my-15">{{ $t('main.faqSection.changeSectionName') }}</h2>
            <LangButtons class="my-15" :locale="locale" @selectLocale="selectLocale" />
            <CRInput
                class="my-15"
                v-model="name[locale]"
                required
                :errors="errors?.name"
                :label="$t('placeholder.sectionName')"
                :placeholder="$t('placeholder.sectionName')"
                @onChange="$filters.clearError(errors, 'name')"
            />
            <CRButton class="my-15" @click="edit" :disabled="!name.lt">
                {{ $t('buttons.update') }}
            </CRButton>
        </div>
    </BasicModal>
</template>

<script>
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import LangButtons from '@/components/Common/Buttons/LangButtons';
    import { langMixin } from '@/utils/mixins';
    import { FAQSectionApi } from '@/api';

    export default {
        name: 'CreateFAQItemModal',
        components: { LangButtons, CRInput, BasicModal, CRButton },
        mixins: [langMixin],
        props: {
            editedSection: {
                type: Object,
                default: () => {},
            },
        },
        emits: ['close', 'updated'],
        data() {
            return {
                name: {
                    en: '',
                    lt: '',
                },
                errors: {},
            };
        },
        methods: {
            async edit() {
                try {
                    const response = await FAQSectionApi.update(this.editedSection.id, { name: this.name });
                    this.$emit('updated', response.data);
                } catch (error) {
                    this.errors = error.errors;
                }
            },
        },
        mounted() {
            if (this.editedSection) {
                this.name = { ...this.editedSection.name };
            }
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-center-justify-center;

        width: 100%;

        padding: 28px 10px 10px;

        h2 {
            font-size: $font-26;
            margin-bottom: 18px;
        }

        .button__wrapper {
            @include row-align-center-justify-around;
            flex-wrap: wrap;

            width: 300px;

            .select-btn {
                width: 30%;
                margin-bottom: 18px;
            }
        }
    }
</style>
